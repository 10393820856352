<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="aligncenter wp-image-22891" src="@/assets/news/4/1.png" alt="" width="675" height="452"  sizes="(max-width: 675px) 100vw, 675px"><br>
          <strong><a href="/por">葡萄牙移民</a>局正式变身</strong></p>
        <p>葡萄牙政府部长理事会在《共和国日报》公布一项决议，外国人和边境服务局（SEF）于4月15日起正式进行重组，此前的SEF被外国人和难民庇护服务局（SEA）取代。</p>
        <p>部长会议发布的决议确认了“外国人和庇护服务局（SEA）的成立，它是继SEF之后的一个新的中央服务机构，根据负责内政管理领域的政府成员进行了分层组织，具有行政自主权”。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22895" src="@/assets/news/4/2.jpg" alt="" width="671" height="501"  sizes="(max-width: 671px) 100vw, 671px"></p>
        <p>此外，前SEF的执法权力移交给了共和国卫队（GNR），公安警察（PSP）和司法警察（PJ）。</p>
        <p>对于新的SEA，在执行移民事务政策方面将承担“技术和行政任务，例如文档、数据库管理，与申根地区以及与欧洲边界和庇护机构的关系与合作领域”。换句话说，正如内政部长Eduardo Cabrita早已宣布的那样，新的SEA主要负责支持居住在葡萄牙的移民和难民。</p>
        <p>而重组后的SEA和IRN，对于工作内容进行了更进一步的精简和提高了其针对性，相信也更有利于提升其工作效率，居留卡审理提速也是指日可待了。</p>
        <p>另一方面，自4月19日开始，由于政府对于本国疫情较好的控制，葡萄牙移民局已经开始恢复预约录指纹业务（阿尔布费拉Albufeira，菲盖拉·达·福斯Figueira da Foz和波尔蒂芒Portimão三个移民局分局除外）。此前已经被取消的预约申请，移民局会逐步通知新的时间；近期新的首次申请、新的续签预约情况均需要等待各移民局的进一步确认办理名额。</p>
        <p><strong>葡萄牙你值得拥有</strong></p>
        <p><strong>有利的税收政策</strong></p>
        <p>葡萄牙的税收优惠也是很多外国人选择居住于此的原因。在葡萄牙的外国人可以享受非习惯性居民的税法(NHR)，该法对居留的头10年提供大量免税，并对几乎所有外国收入免税。收入类型包括养老金、投资、租金收入和资本收益等等。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22894" src="@/assets/news/4/3.jpg" alt="" width="685" height="489"  sizes="(max-width: 685px) 100vw, 685px"></p>
        <p><strong>护照含金量高</strong></p>
        <p>日前，咨询公司Henley&amp;Partners发布了2020年第四季度“亨利护照指数排名”(Henley &amp;Partners Passport Index)。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22893" src="@/assets/news/4/4.png" alt="" width="673" height="485"  sizes="(max-width: 673px) 100vw, 673px"></p>
        <p style="text-align: center;">（完整排行，可至官网查询：https://www.henleypassportindex.com/global-ranking）</p>
        <p>在新一期的排名中，葡萄牙护照排名NO.6，超越美、英、澳、加，免签国家/地区高达186个。2006年至今，葡萄牙在该排名中稳固在前十。</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22892" src="@/assets/news/4/5.png" alt="" width="558" height="216"></p>
        <p><strong>葡萄牙购房移民新政正式出台</strong></p>
        <p>当地时间2月12日，葡萄牙政府发布了2021年第14法条，公布了2020年12月22日部长会议批准通过的黄金居留修正案将从2022年1月1日正式生效，明确了该国黄金签证倍受期待的新政细节，提高了基金投资门槛，缩小了购房移民的地理范围。</p>
        <p>延伸阅读：“移”早不“移”迟：葡萄牙黄金居留新政正式出台！</p>
        <p>1.存款金额提至150万欧以上（之前为100万欧元）；<br>
          2.科研类投资金额提至50万以上（之前为35万欧元）；<br>
          3.购买基金金额提至50万欧以上（之前为35万欧元）；<br>
          4.成立公司金额提至50万欧以上（之前为35万欧元）；<br>
          5.50万欧以上住宅类房产或35万欧以上翻新住宅类房产，只能在内陆地区、马德拉群岛和亚速尔群岛购买。</p>
        <p>在2022年1月1日之前提交的投资移民申请仍按现行法案执行，之后主申请人的更新、家庭团聚的申请及更新都不受法案变动影响。</p>
        <p>换句话说，备受中国投资人青睐的里斯本、波尔图<a href="/">购房移民</a>正式进入了不足一年的“倒计时”。仍有意在房价升值潜力较大的里斯本、波尔图置业的投资人还应早作打算，把握住这段窗口期，抓紧上车。</p>
        <p>（由于政策信息会实时更新，可能发生变动，以上内容仅供大家参考）</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "4",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:4,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>